import Image from "gatsby-image"
import React from "react"

const About = props => {
  return (
    <article className="about">
      {props.image && (
        <Image
          fluid={props.image.childImageSharp.fluid}
          className="about-img"
        />
      )}
      <div className="about-info">
        <p className="about-desc">{props.paragraph}</p>
      </div>
    </article>
  )
}

export default About
