import "../css/main.scss"

import { Grid, Menu } from "semantic-ui-react"

import { Footer } from "./"
import { Link } from "gatsby"
import React from "react"

const Layout = ({ children }) => {
  return (
    <div className="app">
      <Menu fixed="top">
        <Menu.Item as={Link} to="/" header>
          Accueil
        </Menu.Item>
        <Menu.Item as={Link} to="/biography">
          Biographie
        </Menu.Item>
        <Menu.Item as={Link} to="/gallery">
          Gallerie
        </Menu.Item>
        <Menu.Item as={Link} to="/contact">
          Contact
        </Menu.Item>
      </Menu>
      <Grid>
        <Grid.Column width={16}>
          {/* Main content */}
          <main>{children}</main>
        </Grid.Column>
      </Grid>
      <Footer />
    </div>
  )
}

export default Layout
