import About from "./About"
import React from "react"
import styled from "styled-components"

const Abouts = ({ nodes }) => {
  return (
    <Wrapper className="section abouts">
      <div className="section-center abouts-center">
        {nodes.map(node => {
          return node.info.map(about => {
            return <About key={about.id} {...about} />
          })
        })}
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.section``
export default Abouts
