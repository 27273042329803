import {} from "react-icons/fa"

import React from "react"

const data = [
  // {
  //   id: 1,
  //   icon: <FaFacebookSquare className="social-icon"></FaFacebookSquare>,
  //   url: "https://www.twitter.com",
  // },
  // {
  //   id: 2,
  //   icon: <FaLinkedin className="social-icon"></FaLinkedin>,
  //   url: "https://www.twitter.com",
  // },
  // {
  //   id: 3,
  //   icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
  //   url: "https://www.twitter.com",
  // },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
